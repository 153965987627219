<template>
  <section id="hero">
    <v-img
      :min-height="minHeight"
      max-height="100vh"
      :src="require('@/assets/background-home.jpg')"
      class="white--text"
      gradient="to right, rgba(5, 11, 31, .8), rgba(5, 11, 31, .8)"
    >
      <v-container class="fill-height px-4 py-12">
        <v-responsive
          class="d-flex align-center"
          height="100%"
          max-width="700"
          width="100%"
        >
          <base-img
            :src="require('@/assets/logo_home_header.png')"
            contain
            max-width="150"
          />
          <base-heading title="Des idées, des envies ? " />

          <base-body>
           Des compétences pour des réalisations sur mesures.
          </base-body>

          <div
            :class="$vuetify.breakpoint.smAndDown ? 'flex-column align-start' : 'align-center'"
            class="d-flex flex-wrap"
          >
<!--            <base-btn>-->
<!--              En savoir plus-->
<!--            </base-btn>-->
          </div>
        </v-responsive>
      </v-container>
    </v-img>
  </section>
</template>

<script>
  export default {
    name: 'SectionHero',

    provide: {
      theme: { isDark: true },
    },

    computed: {
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },
    },
  }
</script>
